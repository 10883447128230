
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import BottomSheet from "@/components/base/BottomSheet.vue";
import CartContent from "@/components/item-market/CartContent.vue";
import { UserItemEntity } from "@/entities/user-item.entity";

@Component({
  components: {
    BottomSheet,
    CartContent,
  },
})
export default class CartBottomSheet extends Vue {
  @VModel({ type: Boolean }) show!: boolean;
  @Prop() items!: UserItemEntity[];
}
