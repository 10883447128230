
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Watch,
} from "vue-property-decorator";
import ItemList from "@/components/items/ItemList.vue";
import { UserItemEntity } from "@/entities/user-item.entity";
import ScrollFetchMore from "@/components/base/ScrollFetchMore.vue";
import gql from "graphql-tag";
import { UserItemWithoutUserFragment } from "@/graphql/fragments";
import { AuthUserEntity } from "@/entities/user.entity";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import Cart from "@/components/item-market/Cart.vue";
import TopAppBar from "@/components/base/TopAppBar.vue";
import { MaxUserItemPriceQuery } from "@/graphql/queries";
import { useFindAll } from "@/graphql/use-find-all";
import { mongoTextSearch } from "@/helpers/mongo-text-search.helper";

@Component({
  components: {
    ScrollFetchMore,
    ItemList,
    TopAppBar,
    Cart,
  },
  metaInfo: {
    title: "Магазин скинов",
  },
  apollo: {
    maxPrice: {
      update(data) {
        if (typeof data.maxUserItemPrice === "number") {
          this.priceRange[1] = Math.ceil(data.maxUserItemPrice);
        }

        return typeof data.maxUserItemPrice === "number"
          ? Math.ceil(data.maxUserItemPrice)
          : null;
      },
      query: MaxUserItemPriceQuery,
      fetchPolicy: "cache-and-network",
    },
    items: {
      update(data) {
        return data.findAllUserItem
          ? data.findAllUserItem.map(
              (item) =>
                new UserItemEntity(
                  Object.assign({}, item, { price: item.buyPrice })
                )
            )
          : [];
      },
      skip() {
        return this.maxPrice === null;
      },
      fetchPolicy: "cache-and-network",
      debounce: 300,
      query: useFindAll("UserItem", UserItemWithoutUserFragment),
      variables() {
        return {
          options: {
            sort: this.sortBy,
          },
          filter: {
            ...(this.searchText
              ? { _text: { search: mongoTextSearch(this.searchText) } }
              : {}),
            price: { gte: this.priceRange[0], lte: this.priceRange[1] },
            state: { eq: "DEFAULT" },
            userId: { exists: false },
          },
        };
      },
    },
  },
})
export default class ItemMarket extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  private showCart = false;
  private priceRange = [0, 999999];
  private maxPrice: number | null = null;
  private searchText = "";
  private sortBy = "-price";
  private items: UserItemEntity[] | null = null;
  private selectedItems: UserItemEntity[] = [];

  get colsMap() {
    return [
      [900, 6],
      [750, 5],
      [500, 4],
      [300, 3],
      [0, 2],
    ];
  }

  @Watch("sortBy")
  @Watch("searchText")
  @Watch("priceRange")
  onVariablesChanged() {
    const scroll = this.$el.querySelector("#item-market-scroll");
    if (scroll) {
      scroll.scrollTop = 0;
    }
  }

  select(item: UserItemEntity) {
    const index = this.selectedItems.findIndex(
      (item1) => item1 && item1._id === item._id
    );

    if (index === -1) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  get pageHeight() {
    return (
      this.$vuetify.breakpoint.height - this.$vuetify.application.insetFooter
    );
  }

  mounted() {
    this.$el
      .querySelector("#item-market-scroll")!
      .classList.add("box-items-container");
    this.$el.querySelector("#item-market-scroll")!.classList.add("pa-4");
  }
}
