
import {
  Component,
  Inject,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import CartDialog from "@/components/item-market/CartDialog.vue";
import { UserItemEntity } from "../../entities/user-item.entity";
import CartBottomSheet from "@/components/item-market/CartBottomSheet.vue";
import { IS_MOBILE_SYMBOL } from "@/constants";

@Component({
  components: {
    CartDialog,
    CartBottomSheet,
  },
})
export default class Cart extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @VModel({ type: Boolean }) show!: boolean;
  @Prop() items!: UserItemEntity[];
  @Prop() attach!: string;

  @Watch("items.length")
  itemsLengthWatcher(value, oldValue) {
    if (oldValue && !value && this.show) {
      this.show = false;
    }
  }

  onSuccess(...args) {
    this.$emit("success", ...args);
    this.$emit("remove-all");
  }
}
