
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import { UserItemEntity } from "../../entities/user-item.entity";
import Item from "@/components/items/Item.vue";
import { Config } from "vuescroll";
import * as sumBy from "lodash/sumBy";
import gql from "graphql-tag";
import { UserItemWithUserFragment } from "@/graphql/fragments";
import Price from "@/components/base/Price.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";
import { USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";

@Component({
  components: {
    Item,
    GradientBtn,
    Price,
  },
})
export default class CartContent extends Vue {
  @Prop({ default: () => [] }) items!: UserItemEntity[];
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  private boughtItemIds: string[] = [];
  private loading = false;
  private dialog = false;
  private scrollOptions: Config = {
    rail: {
      keepShow: false,
    },
    bar: {
      disable: true,
    },
    vuescroll: {
      mode: "slide",
      snapping: {
        enable: true,
        width: 150,
      },
      zooming: false,
    },
    scrollPanel: {
      scrollingX: true,
      scrollingY: false,
    },
  };

  get price() {
    return Number(sumBy(this.items, "price").toFixed(2));
  }

  get remainBalance() {
    return this.user ? Number((this.price - this.user.balance).toFixed(2)) : 0;
  }

  async close() {
    this.dialog = false;
    this.$emit("success");
    this.$emit("close");
  }

  async buy() {
    this.loading = true;
    const boughtItems = await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($input: BuyUserItemsInput!) {
            buyUserItems(input: $input) {
              ...UserItemWithUser
            }
          }
          ${UserItemWithUserFragment}
        `,
        variables: {
          input: {
            itemIds: this.items.map(({ _id }) => _id),
          },
        },
      })
      .catch((e) => {
        this.$notify({
          type: "error",
          text: "Не удалось купить предметы, деньги были возвращены на Ваш баланс.",
        });
      });

    this.loading = false;

    if (!boughtItems) {
      this.dialog = false;
      return;
    }

    this.boughtItemIds = boughtItems.data.buyUserItems;

    if (this.boughtItemIds.length !== this.items.length) {
      this.$notify({
        type: "info",
        text: "Некоторые предметы не удалось купить, деньги за них были возвращены на Ваш баланс.",
      });
    }
  }
}
